<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px" style="display: flex">
      <div>
        <el-row style="width:85%!important;">
          <el-col :span="8">
            <el-form-item label="平台账号">
              <el-select v-model="form.account" value-key="id" clearable filterable @change="PlatformAccountChange">
                <el-option label="全部" value="">全部</el-option>
                <el-option v-for="item in PlatformAccountList" :key="item.id" :label="item.account" :value="item.account" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平台站点">
              <el-select v-model="form.site" multiple value-key="id" clearable filterable @change="PlatformsiteChange">
                <el-option label="全部" value="">全部</el-option>
                <el-option v-for="item in terraceData" :key="item.id" :label="item.name" :value="item.site" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="标签">
              <el-cascader v-model="form.labelsId" :options="tagData" clearable :props="props">
                <template slot-scope="{ data }">
                  <span>{{ data.labelName }}</span>
                  <span> ({{ data.labelCount }}) </span>
                </template>
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-form-item :label="$t('title.Platformsite')">
            <el-radio-group v-model="form.site" @change="PlatformsiteChange">
              <el-radio label="">{{ $t("title.OrderAll") }}</el-radio>
              <el-radio v-for="item in terraceData" :key="item.site" class="mr-3" :label="item.site">{{ item.name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row> -->
        <!-- 标签 -->
        <el-row />
        <el-row>
          <el-form-item :label="$t('title.Attachedfile')">
            <el-radio-group v-model="form.isAttachment" @change="isAttachmentChange">
              <el-radio label="">{{ $t("title.OrderAll") }}</el-radio>
              <el-radio label="1">{{ $t("title.Yes") }}</el-radio>
              <el-radio label="0">{{ $t("title.No") }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="$t('title.Receivedtime')">
            <el-radio-group v-model="form.receivedTime" @change="changeDate">
              <el-radio label="">{{ $t("title.OrderAll") }}</el-radio>
              <el-radio label="今天">{{ $t("title.today") }}</el-radio>
              <el-radio label="昨天">{{ $t("title.yesterday") }}</el-radio>
              <el-radio label="3天内">{{ $t("title.Inthreedays") }}</el-radio>
              <el-radio label="7天内">{{ $t("title.Insevendays") }}</el-radio>
              <el-radio label="30天内">{{ $t("title.Inamonth") }}</el-radio>
              <el-radio @click.native="definetimedialogVisible = true">自定义</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row type="flex" justify="space-between">
          <div style="width: 85%">
            <el-row class="Row-Input" :class="{ 'show-row': showRow }">
              <!-- 消息id -->
              <el-col :span="8">
                <el-form-item label="消息ID">
                  <el-input v-model="form.messageId" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>

              <!-- Email -->
              <el-col :span="8">
                <el-form-item label="Email">
                  <el-input v-model="form.emailId" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>

              <!-- 平台订单号 -->
              <el-col :span="8">
                <el-form-item :label="$t('title.platNumber')">
                  <el-input v-model="form.orderCoder" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>

              <!-- BU -->
              <el-col :span="8">
                <el-form-item label="BU">
                  <el-input v-model="form.bu" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>

              <!-- style -->
              <el-col :span="8">
                <el-form-item label="style">
                  <el-select v-model="form.style" value-key="id" clearable filterable @change="styleSelectChange">
                    <el-option v-for="item in styleOptions" :key="item.id" :label="item.styleName" :value="item.styleName" />
                  </el-select>
                </el-form-item>
              </el-col>

              <!-- color -->
              <el-col :span="8">
                <el-form-item label="color">
                  <el-select v-model="form.color" clearable filterable>
                    <el-option v-for="item in colorOptions" :key="item.id" :label="item.colorName" :value="item.colorName" />
                  </el-select>
                </el-form-item>
              </el-col>

              <!-- size -->
              <el-col :span="8">
                <el-form-item label="size">
                  <el-select v-model="form.size" clearable filterable>
                    <el-option v-for="item in sizeOptions" :key="item.id" :label="item.size" :value="item.size" />
                  </el-select>
                </el-form-item>
              </el-col>

              <!-- SKU -->
              <el-col :span="8">
                <el-form-item label="SKU">
                  <el-input v-model="form.sku" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>

              <!-- 产品等级 -->
              <el-col :span="8">
                <el-form-item label="产品等级">
                  <el-select v-model="form.position" placeholder="请选择" clearable>
                    <el-option value="P3" label="P3" />
                    <el-option value="P2" label="P2" />
                    <el-option value="P1" label="P1" />
                    <el-option value="P0" label="P0" />
                    <el-option value="新品" label="新品" />
                  </el-select>
                </el-form-item>
              </el-col>

              <!-- 关键字 -->
              <el-col :span="8">
                <el-form-item label="关键字">
                  <el-input v-model="form.messageKey" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>

              <el-col :span="10">
                <el-form-item :label="$t('title.time')">
                  <el-date-picker v-model="timerange" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <el-row style="width: 15%">
            <el-col class="row-center">
              <el-button type="primary" :loading="tableLoading" @click="_getpagedlist(0,true)">{{ $t("page.search") }}
              </el-button>
              <el-button @click="pagedlistReset">{{
                $t("page.reset")
              }}</el-button>
              <el-button type="text" @click="showRow = !showRow">{{
                showRow ? $t("page.hide") : $t("page.expand")
              }}</el-button>
            </el-col>
          </el-row>
        </el-row>

        <el-row :span="24" style="margin: 5px 0">
          <el-col :span="24" style="display: flex; height: 36px">
            <el-button size="small" @click="getupdatemailstatusbylist('replied', 2)">{{ $t("title.Markasreplied") }}
            </el-button>
            <el-button size="small" @click="getupdatemailstatusbylist('read', 1)">{{ $t("title.Markasreaded") }}
            </el-button>
            <el-button size="small" @click="getupdatemailstatusbylist('read', 0)">{{ $t("title.Markasunread") }}
            </el-button>
            <el-button size="small" @click="getupdatemailstatusbylist('star', 1)">{{ $t("title.Markasstar") }}
            </el-button>
            <el-button v-if="form.account" type="primary" size="small" @click="PullmailbyhanddialogVisible = true">
              {{ $t("title.emailsmanually") }}</el-button>
            <el-button type="primary" size="small" @click="handleDialogVisibleLable = true">
              标签
            </el-button>
            <el-button v-if="form.messageStatus == 1" type="primary" size="small" @click="noticeVisible = true">
              提醒
            </el-button>
            <el-button type="primary" size="small" @click="exportEmail">
              导出
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <table-select-action :quantity="multipleSelection.length" @reset="handleTableSelectReset" />
    <!-- 手工拉取邮件 -->
    <el-dialog title="手工拉取邮件" :visible.sync="PullmailbyhanddialogVisible" width="40%">
      <el-date-picker v-model="timeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="PullmailbyhandSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 自定义提醒时间设置 -->
    <el-dialog title="自定义提醒日期" :visible.sync="defineNoticeTimeVisible" width="30%">
      <el-date-picker v-model="value1" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="defineNoticeTimeVisible = false">取 消</el-button>
        <el-button type="primary" @click="defineNoticeTimeSure">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="自定义日期" :visible.sync="definetimedialogVisible" width="40%">
      <el-date-picker v-model="timerange" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDefined">取 消</el-button>
        <el-button type="primary" @click="definetimeSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 标签 -->
    <el-dialog :visible.sync="handleDialogVisibleLable" width="40%">
      <el-tree ref="tree" class="my-4 menu-true" highlight-current node-key="id" :expand-on-click-node="false" :data="lableDatas" :props="defaultProps" show-checkbox />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="tagSure">确定</el-button>
        <el-button size="small" @click="addLabel">新建/管理标签</el-button>
      </span>
    </el-dialog>
    <!-- 新增管理标签 -->
    <el-dialog title="新建/管理标签" :visible.sync="handleDialogVisiblewRemind" width="50%" @close="closeTag">
      <el-tree :data="lableDatas" node-key="id" default-expand-all :props="defaultProps" :expand-on-click-node="false">
        <el-row slot-scope="{ node, data }" class="custom-tree-node">
          <el-col>{{ node.label }}</el-col>
          <el-col>
            <el-button type="text" size="mini" @click="() => edit(data)">
              编辑
            </el-button>
            <el-button v-if="data.parentId === -1" type="text" size="mini" @click="() => append(2, data)">
              添加二级标签
            </el-button>
            <el-button v-else-if="node.level===2" type="text" size="mini" @click="() => append(3, data)">
              添加三级标签
            </el-button>
            <el-button type="text" size="mini" @click="() => remove(node, data)">
              删除
            </el-button>
          </el-col>
        </el-row>
      </el-tree>
      <el-button v-if="show === 'button'" icon="el-icon-plus" style="width: 100%" class="mt-4" @click="show = 'input'">
        添加一级标签</el-button>
      <el-row v-if="show === 'input'" :gutter="20" class="mt-3">
        <el-col :span="15">
          <el-input ref="labelInput" v-model="labelName" />
        </el-col>

        <el-col :span="3">
          <el-button @click="addSure(labelName, labelLevel, parentId, id)">{{
            $t("page.sure")
          }}</el-button>
        </el-col>
        <el-col :span="3">
          <el-button @click="addCancel">{{
            $t("title.cancel")
          }}</el-button>
        </el-col>
      </el-row>
      <div style="text-align: right; margin-top: 18px">
        <el-button @click="handleDialogVisiblewRemind = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 提醒 -->
    <el-dialog :visible.sync="noticeVisible" width="25%">
      <p style="display: flex; justify-content: space-between;cursor: pointer;" @click="handleRemind(1)">
        <span>明天 </span><span>中午12:00</span>
      </p>
      <p style="display: flex; justify-content: space-between;cursor:pointer;" @click="handleRemind(2)">
        <span>后天 </span><span>中午12:00</span>
      </p>
      <p style="display: flex; justify-content: space-between;cursor:pointer;" @click="handleRemind(3)">
        <span>三天内</span><span>中午12:00</span>
      </p>
      <p style="display: flex; justify-content: space-between;cursor: pointer;" @click="handleRemind(7)">
        <span>七天内</span><span>中午12:00</span>
      </p>
      <p @click="defineNoticeTimeVisible = true"><span>自定义</span></p>
      <p @click="handleNotice()">
        <span style="cursor: pointer;">查看 </span><span class="count">( {{ warnCount }} )</span>
      </p>
    </el-dialog>
    <!-- tab栏切换 -->
    <el-tabs v-model="form.messageStatus" @tab-click="handleTabsClick">
      <el-tab-pane v-for="item in StatusGroup" :key="item.messageStatus" :name="item.messageStatus + ''">
        <span slot="label">{{ item.messageStatusName
        }}<span class="count ml-3">({{ item.messageCount }})</span></span>
        <BuyerEmailTable
          ref="table"
          :form="form"
          :table-data="tableData"
          :table-loading="tableLoading"
          :table-height="tableHeight"
          :terrace-data="terraceData"
          :disabled-flag="disabledFlag"
          @getMul="getMultiple"
          @refresh="_getpagedlist(0)"
          @getthreadList="getthreadList"
          @getmultipleSelection="getmultipleSelection"
        />
      </el-tab-pane>
    </el-tabs>
    <el-pagination :current-page="pager.current" :page-sizes="[20, 50, 100, 200, 300]" :page-size="pager.size" layout="total, sizes, prev, pager, next, jumper" :total="pager.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
  </div>
</template>

<script>
import {
  batchUpdateMessageWarnById,
  gettemplatetypelistbywhere,
  messagesGetLabel,
  getAmazonPagedList,
  getAmazonMessageStatus,
  mailaccountinfo,
  getmailormessageinfobyhand,
  batchAmazonUpdateMessageStatus,
  batchAmazonUpdateMessageStartedById,
  updateAmazonMessageReadById,
  getWarnMessageCount,
  messagesSaveLabel,
  messagesDeleteLabel,
  messagesUpdateLabel,
  selectMakeLabel,
  batchMarkLabelInsert
} from '@/api/service-management'
import {
  queryColorList,
  querySizeList,
  queryStyleList
} from '@/api/omsorder.js'
import mixin from '@/mixin/oms-mixin'
import TableSelectAction from '@/components/TableSelectAction'
import BuyerEmailTable from '../Walmart-buyer-email/components/BuyerEmailTable'
import { deepClone } from '@/utils'
// import store from '@/store'
export default {
  components: {
    TableSelectAction,
    BuyerEmailTable
  },
  mixins: [mixin],
  data() {
    return {
      checkNotice: false,
      disabledFlag: true,
      defineNoticeTimeVisible: false,
      value1: '',
      arrList: [],
      expandedKeys: [],
      id: '',
      sure: false,
      labelName: '',
      labelLevel: 1,
      parentId: -1,
      show: 'button',
      warnTime: '',
      noticeVisible: false,
      handleDialogVisiblewRemind: false,
      lableDatas: [],
      defaultProps: {
        children: 'childNodes',
        label: 'labelName'
      },
      definetimedialogVisible: false,
      handleDialogVisibleLable: false,
      showRow: false,
      options: [],
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      userName: '',
      threadList: [],
      StatusGroup: [],
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      timeArr: [],
      PullmailbyhanddialogVisible: false,
      type: '',
      tableHeight: '400',
      tableLoading: false,
      multipleSelection: [],
      form: {
        account: '',
        site: 'US',
        messageStatus: '0',
        platform: 'WALMART',
        labelsId: '',
        receivedTime: ''
      },
      labelsId: '',
      timerange: [],
      terraceData: [],
      PlatformAccountList: [],
      tableData: [],
      warnCount: '',
      tagData: [],
      props: {
        checkStrictly: true,
        value: 'id',
        label: 'labelName',
        children: 'childNodes'
      }
    }
  },

  computed: {
    getpagedlistParams() {
      this.getLabel()
      let isWarn = ''
      this.checkNotice ? (isWarn = 1) : ''
      const {
        isAttachment,
        site,
        emailId,
        messageId,
        account,
        orderCoder,
        bu,
        style,
        color,
        size,
        sku,
        position,
        messageKey,
        messageStatus
      } = this.form
      const [lastModifyTimeStart, lastModifyTimeEnd] = this.timerange || []
      return Object.assign(
        {},
        {
          isAttachment,
          site: site.includes('') ? '' : site.toString(),
          emailId,
          messageId,
          account,
          orderCoder,
          bu,
          style,
          color,
          size,
          sku,
          position,
          messageKey,
          messageStatus,
          labelsId: this.labelsId
        },
        this.pager,
        {
          lastModifyTimeStart,
          lastModifyTimeEnd,
          messageType: 0,
          isWarn,
          platform: 'AMAZON'
        }
      )
    },
    getmailallstatuscountbytypeParams() {
      let isWarn = ''
      this.checkNotice ? (isWarn = 1) : ''
      const {
        isAttachment,
        site,
        emailId,
        messageId,
        account,
        orderCoder,
        bu,
        style,
        color,
        size,
        sku,
        position,
        messageKey
      } = this.form
      const [lastModifyTimeStart, lastModifyTimeEnd] = this.timerange || []
      return Object.assign(
        {},
        { lastModifyTimeStart, lastModifyTimeEnd, platform: 'AMAZON' },
        {
          isAttachment,
          site: site.includes('') ? '' : site.toString(),
          emailId,
          messageId,
          account,
          orderCoder,
          bu,
          style,
          color,
          size,
          sku,
          position,
          messageKey,
          labelsId: this.labelsId
        },
        { messageType: 0, isWarn }
      )
    },
    exportUrl() {
      this.getLabel()
      const obj = {}
      const {
        isAttachment,
        site,
        emailId,
        messageId,
        account,
        orderCoder,
        bu,
        style,
        color,
        size,
        sku,
        position,
        messageKey,
        messageStatus
      } = this.form
      const [lastModifyTimeStart, lastModifyTimeEnd] = this.timerange || []
      const OBJ = Object.assign(
        {},
        {
          isAttachment,
          site: site.includes('') ? '' : site.toString(),
          emailId,
          messageId,
          account,
          orderCoder,
          bu,
          style,
          color,
          size,
          sku,
          position,
          messageKey,
          labelsId: this.labelsId,
          messageStatus,
          messageType: 0
        },
        { lastModifyTimeStart, lastModifyTimeEnd, platform: 'AMAZON' }
      )
      for (var key in OBJ) {
        OBJ[key] ? (obj[key] = OBJ[key]) : ''
      }
      console.log(obj)
      let url = ''
      for (var i in obj) {
        url = `${i}=${obj[i]}` + '&' + url
      }
      url = url.substr(0, url.length - 1)
      return process.env.VUE_APP_ERP_NEW_API + 'mos-web-core/Customer/exportMessageInfos?messageType=0&' + url
    }
  },

  created() {},
  mounted() {
    this._selectMakeLabel()
    this._getWarnMessageCount()
    this.getType()
    this._messagesGetLabel()
    this._queryColorList()
    this._querySizeList()
    this._queryStyleList()
    this.getmailaccountinfo('AMAZON')
    this.getmailaccountInfo('AMAZON')
    this._getpagedlist(0)
    this._getmailallstatuscountbytype()
  },
  methods: {
    closeTag() {
      this.show = 'button'
    },
    getLabel() {
      this.labelsId = this.form.labelsId
      this.labelsId = this.labelsId && this.labelsId[this.labelsId.length - 1]
    },
    handleNotice() {
      this.checkNotice = true
      this._getpagedlist(0)
      this.noticeVisible = false
    },
    exportEmail() {
      window.open(this.exportUrl, '_blank')
    },
    async defineNoticeTimeSure() {
      const { code, msg } = await batchUpdateMessageWarnById(
        this.threadList,
        1,
        this.value1
      )
      code === 0 && this.$message.success(msg)
      this.defineNoticeTimeVisible = false
      this.value1 = ''
    },
    async _selectMakeLabel() {
      const { datas } = await selectMakeLabel('AMAZON', 0)
      this.tagData = datas
    },
    cancelDefined() {
      this.definetimedialogVisible = false
      this.form.receivedTime = ''
    },
    async _getWarnMessageCount() {
      const { datas } = await getWarnMessageCount('Amazon', 1)
      this.warnCount = datas
    },
    async getType() {
      const { data } = await gettemplatetypelistbywhere({
        platform: 'Amazon'
      })
      this.typeData = data
    },
    unique(arr) {
      return Array.from(new Set(arr))
    },
    setName(datas) {
      // 遍历树  获取id数组
      for (var i in datas) {
        this.expandedKeys.push(datas[i].id)
        if (datas[i].childNodes) {
          this.setName(datas[i].childNodes)
        }
      }
      // console.log(this.expandedKeys);
    },
    async tagSure() {
      // console.log(this.$refs.tree.getCheckedKeys())
      if (!this.multipleSelection.length) {
        this.$message({
          type: 'warning',
          message: '请至少选择一条数据'
        })
        return
      }
      const arr = []
      let obj = {}
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (this.multipleSelection[i].orderList.length) {
          for (var j = 0; j < this.multipleSelection[i].orderList.length; j++) {
            obj.messageId = this.multipleSelection[i].id
            obj.orderId = this.multipleSelection[i].orderList[j].id
            obj.labelId = this.$refs.tree
              .getHalfCheckedKeys()
              .concat(this.$refs.tree.getCheckedKeys())
            arr.push(obj)
            obj = {}
          }
        } else {
          obj.messageId = this.multipleSelection[i].id
          obj.orderId = ''
          obj.labelId = this.$refs.tree
            .getHalfCheckedKeys()
            .concat(this.$refs.tree.getCheckedKeys())
          arr.push(obj)
          obj = {}
        }
      }
      // console.log(arr)
      const { code, msg } = await batchMarkLabelInsert(true, arr)
      code === 0 && this.$message.success(msg)
      this.handleDialogVisibleLable = false
      this._getpagedlist(0)
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 选择style 带出color
    styleSelectChange(value) {
      const data = this.styleOptions.find((item) => item.styleName === value)
      this._queryColorList(data.id)
      this._querySizeList(data.id)
    },
    // color信息获取
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    definetimeSure() {
      this._getpagedlist(0)
      this.definetimedialogVisible = false
    },
    changeDate(val) {
      this.timerange = []
      if (val === '今天') {
        this.timerange.push(this.setDate(0, 0), this.setDate(0, 0))
      } else if (val === '昨天') {
        this.timerange.push(this.setDate(-1, 0), this.setDate(-1, 0))
      } else if (val === '3天内') {
        this.timerange.push(this.setDate(-3, 0), this.setDate(0, 0))
      } else if (val === '7天内') {
        this.timerange.push(this.setDate(-7, 0), this.setDate(0, 0))
      } else if (val === '30天内') {
        this.timerange.push(this.setDate(-30, 0), this.setDate(0, 0))
      }
      this._getpagedlist(0)
    },
    getmultipleSelection(val) {
      this.multipleSelection = val
      // console.log(this.multipleSelection);
    },
    getthreadList(val) {
      // console.log(val)
      this.threadList = val
    },
    // 亚马逊批量操作
    getupdatemailstatusbylist(operate, status) {
      if (this.threadList.length === 0) {
        this.$message.error(this.$t('title.select'))
        return
      }
      let res = {}
      console.log(this.threadList)
      this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(async() => {
        if (operate === 'replied' && status === 2) {
          res = await batchAmazonUpdateMessageStatus(this.threadList, status)
        } else if (operate === 'star' && (status === 0 || status === 1)) {
          res = await batchAmazonUpdateMessageStartedById(
            this.threadList,
            status
          )
        } else if (operate === 'read' && (status === 1 || status === 0)) {
          res = await updateAmazonMessageReadById(this.threadList, status)
        }
        res.code === 0
          ? this.$message.success('操作成功')
          : this.$message.error('操作失败')
        this._getpagedlist(0)
      })
    },
    pagedlistReset() {
      this.form = this.$options.data().form
      this.timerange = []
      this.timeArr = []
      this.checkNotice = false
      this._getpagedlist(0)
    },
    async getmailaccountInfo(platform) {
      const { data } = await mailaccountinfo(platform, {
        account: this.form.account
      })
      // console.log(data)
      data.length
        ? (this.terraceData = data[0].siteInfos)
        : (this.terraceData = [])
    },
    async getmailaccountinfo(platform) {
      const { data } = await mailaccountinfo(platform)
      this.PlatformAccountList = data
      // console.log(data)
    },
    isAttachmentChange() {
      this._getpagedlist(0)
    },
    handleSizeChange(val) {
      this.pager.size = val
      this._getpagedlist(0)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._getpagedlist(1)
    },
    async _getmailallstatuscountbytype() {
      const { datas } = await getAmazonMessageStatus(
        this.getmailallstatuscountbytypeParams
      )
      this.StatusGroup = datas
      this.noticeVisible = false
    },
    // 分页查询
    async _getpagedlist(type, notice) {
      try {
        if (notice && notice === true) {
          this.checkNotice = false
        }
        type === 0 ? (this.pager.current = 1) : ''
        this.tableLoading = true
        const { datas } = await getAmazonPagedList(this.getpagedlistParams)
        const { pager, records } = datas
        const arr = deepClone(records)
        const sku = []
        const style = []
        const bu = []
        let SKU = []
        let BU = []
        let STYLE = []
        for (var i = 0; i < arr.length; i++) {
          arr[i].orderList.map((item) => {
            BU.push(item.bu)
            STYLE.push(item.style)
            SKU.push(item.sku)
          })
          sku.push(SKU)
          style.push(STYLE)
          bu.push(BU)
          SKU = []
          STYLE = []
          BU = []
        }
        console.log(bu)
        for (var j = 0; j < sku.length; j++) {
          for (var k = 0; k < arr.length; k++) {
            arr[k].sku = this.unique(sku[k])
          }
        }
        for (let j = 0; j < style.length; j++) {
          for (let k = 0; k < arr.length; k++) {
            arr[k].style = this.unique(style[k])
          }
        }
        for (let j = 0; j < bu.length; j++) {
          for (let k = 0; k < arr.length; k++) {
            arr[k].bu = this.unique(bu[k])
          }
        }
        console.log(arr)
        this.tableData = arr
        this.tableLoading = false
        this.tableData.forEach((ele, index) => {
          // 遍历ele对象 添加index属性
          ele.index = index
        })
        this.pager = pager
        this._getmailallstatuscountbytype()
        this._getWarnMessageCount()
        this._selectMakeLabel()
      } catch (err) {
        console.log(err)
      } finally {
        this.tableLoading = false
      }
    },
    async PullmailbyhandSure() {
      const [startTime, endTime] = this.timeArr || []
      const { account } = this.form
      const { status } = await getmailormessageinfobyhand(
        'Amazon',
        account,
        startTime,
        endTime
      )
      status === 200
        ? this.$message.success('拉取邮件成功')
        : this.$message.error('拉取邮件失败')
      this.PullmailbyhanddialogVisible = false
    },
    PlatformAccountChange(val) {
      this.form.account = val
      this.getmailaccountInfo('AMAZON')
      this._getpagedlist(0)
    },
    PlatformsiteChange(val) {
      this.form.site = val
      this._getpagedlist(0)
    },
    // 获取所有标签
    async _messagesGetLabel() {
      const { datas } = await messagesGetLabel()
      this.lableDatas = datas
      // console.log(this.lableDatas)
    },

    // 编辑
    async eidtEvent(row) {
      this.$refs.xTree.clearActived().then(async() => {
        try {
          const { code } = await messagesUpdateLabel(row.id, row.labelName)
          code === 0
            ? this.$message.success('修改成功')
            : this.$message.error('修改失败')
        } catch (err) {
          console.log(err)
        }
      })
    },

    setDate(n, type) {
      var day3 = new Date()
      day3.setTime(day3.getTime() + n * 24 * 60 * 60 * 1000)
      var s3 =
        day3.getFullYear() + '-' + (day3.getMonth() + 1) + '-' + day3.getDate()
      if (type === 0) {
        return s3
      } else {
        return s3 + ' 12:00:00'
      }
    },
    async handleRemind(type) {
      if (this.threadList.length === 0) {
        this.$message.error(this.$t('title.select'))
        return
      }
      type &&
        (type === 1
          ? (this.warnTime = this.setDate(1, 1))
          : type === 2
            ? (this.warnTime = this.setDate(2, 1))
            : type === 3
              ? (this.warnTime = this.setDate(3, 1))
              : type === 7
                ? (this.warnTime = this.setDate(7, 1))
                : '')
      console.log(this.warnTime)
      const { code, msg } = await batchUpdateMessageWarnById(
        this.threadList,
        1,
        this.warnTime
      )
      code === 0 && this.$message.success(msg)
      this._getpagedlist(0)
      this.noticeVisible = false
    },
    // 新增/管理标签
    addLabel() {
      this._messagesGetLabel()
      this.handleDialogVisiblewRemind = true
    },
    handleTableSelectReset() {
      this.multipleSelection = []
      for (var i = 0; i < this.$refs.table.length; i++) {
        this.$refs.table[i].$refs.table.clearSelection()
      }
    },
    getMultiple(data) {
      this.multipleSelection = data
    },
    handleTabsClick() {
      this._getpagedlist()
    },
    async remove(node, data) {
      console.log(node, data)
      const { code } = await messagesDeleteLabel(data.id)
      code === 0
        ? this.$message.success('删除成功')
        : this.$message.error('删除失败')
      this.show = 'button'
      this._messagesGetLabel()
    },
    append(level, data) {
      this.show = 'input'
      this.parentId = data.id
      this.labelLevel = level
    },
    edit(data) {
      this.sure = true
      // console.log(node, data);
      this.show = 'input'
      this.labelName = data.labelName
      this.id = data.id
      console.log(this.$refs.labelInput)
    },
    addCancel() {
      this.show = 'button'
    },
    // 新增tag
    async addSure(labelName, labelLevel, parentId, id) {
      // console.log(labelName, labelLevel, parentId, id);
      if (this.sure) {
        const { code } = await messagesUpdateLabel(id, this.labelName)
        code === 0
          ? this.$message.success('修改成功')
          : this.$message.error('修改失败')
      } else {
        const { code } = await messagesSaveLabel({
          labelName,
          labelLevel,
          parentId
        })
        code === 0
          ? this.$message.success('修改成功')
          : this.$message.error('修改失败')
      }
      this._messagesGetLabel()

      this.labelName = ''
      this.labelLevel = 1
      this.parentId = -1
      this.show = 'button'
      this.sure = false
    }
  }
}
</script>

<style scoped lang="scss">
.mark,
.count {
  color: red;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
/deep/ .el-form-item {
  margin-bottom: 3px;
}
/deep/.el-tabs__header {
  margin: 0 !important;
}
.Row-Input {
  height: 38px;
  overflow: hidden;
}
.show-row {
  height: auto;
}
</style>
